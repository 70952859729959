// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormatNumberTokens-module__containerFormatNumberTokens___A8xid{display:grid;grid-gap:5px;gap:5px;font-size:14px;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}.FormatNumberTokens-module__containerFormatNumberTokensNumber___TjzJf{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;white-space:nowrap}.FormatNumberTokens-module__containerFormatNumberTokensNumberDecimal___UM7N9{opacity:var(--text-opacity-4);font-size:12px}", "",{"version":3,"sources":["webpack://./src/components/FormatNumberTokens/FormatNumberTokens.module.scss"],"names":[],"mappings":"AAAA,gEACE,YAAA,CACA,YAAA,CAAA,OAAA,CACA,cAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CAEA,sEACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,qBAAA,CAAA,kBAAA,CAAA,oBAAA,CAEA,kBAAA,CAEA,6EACE,6BAAA,CACA,cAAA","sourcesContent":[".containerFormatNumberTokens {\n  display: grid;\n  gap: 5px;\n  font-size: 14px;\n  align-items: baseline;\n\n  &Number {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    // line-height: 19px;\n    white-space: nowrap;\n\n    &Decimal {\n      opacity: var(--text-opacity-4);\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFormatNumberTokens": "FormatNumberTokens-module__containerFormatNumberTokens___A8xid",
	"containerFormatNumberTokensNumber": "FormatNumberTokens-module__containerFormatNumberTokensNumber___TjzJf",
	"containerFormatNumberTokensNumberDecimal": "FormatNumberTokens-module__containerFormatNumberTokensNumberDecimal___UM7N9"
};
export default ___CSS_LOADER_EXPORT___;
