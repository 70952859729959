// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout-module__wrapper___avaC0{display:grid;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;grid-template-columns:1fr minmax(200px, 62%) 1fr}.Layout-module__wrapper___avaC0>main{padding-bottom:130px;display:grid;min-height:100%}.Layout-module__wrapper___avaC0>main>header{-ms-flex-item-align:start;align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/robot/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,gDAAA,CAEA,qCACE,oBATa,CAYb,YAAA,CAQA,eAAA,CANA,4CAEE,yBAAA,CAAA,qBAAA","sourcesContent":["$actionBarSpace: 130px;\n\n.wrapper {\n  display: grid;\n  justify-content: center;\n  align-items: flex-start;\n  grid-template-columns: 1fr minmax(200px, 62%) 1fr;\n\n  > main {\n    padding-bottom: $actionBarSpace;\n\n    // stretch children\n    display: grid;\n\n    > header {\n      // because of grid\n      align-self: flex-start;\n    }\n\n    // same with menu\n    min-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Layout-module__wrapper___avaC0"
};
export default ___CSS_LOADER_EXPORT___;
