// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!./libs/index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--primary-color: #36d6ae;--green-light: #76ff03;--green-2: #7afaa1;--blue: #000aff;--blue-light: #1fcbff;--yellow: #fcf000;--red: #FF5C00;--grayscale-disabled: #616161;--grayscale-dark: #808080;--grayscale-secondary: #C7C7C7;--grayscale-primary: #fff;--text-opacity-2: 0.7;--text-opacity-3: 0.7;--text-opacity-4: 0.38}", "",{"version":3,"sources":["webpack://./src/style/index.scss"],"names":[],"mappings":"AAEA,MAEE,wBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,eAAA,CACA,qBAAA,CAEA,iBAAA,CAEA,cAAA,CAEA,6BAAA,CACA,yBAAA,CACA,8BAAA,CACA,yBAAA,CAEA,qBAAA,CACA,qBAAA,CACA,sBAAA","sourcesContent":["@import url('./libs/index.scss');\n\n:root {\n  // greens\n  --primary-color: #36d6ae;\n  --green-light: #76ff03;\n  --green-2: #7afaa1;\n\n  --blue: #000aff;\n  --blue-light: #1fcbff;\n\n  --yellow: #fcf000;\n\n  --red: #FF5C00;\n\n  --grayscale-disabled: #616161;\n  --grayscale-dark: #808080;\n  --grayscale-secondary: #C7C7C7;\n  --grayscale-primary: #fff;\n\n  --text-opacity-2: 0.7;\n  --text-opacity-3: 0.7;\n  --text-opacity-4: 0.38;\n}\n\n:export {\n  primaryColor: var(--primary-color);\n  greenLightColor: var(--green-light);\n  redColor: var(--red);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "var(--primary-color)",
	"greenLightColor": "var(--green-light)",
	"redColor": "var(--red)"
};
export default ___CSS_LOADER_EXPORT___;
