// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mint-module__tabs___tBau1{width:max(300px, min(100%, 380px));margin:0 auto}.Mint-module__text___eBy1w{text-align:center;grid-area:2/2/2/2;font-size:16px;position:absolute;bottom:30px;color:var(--grayscale-dark)}.Mint-module__text___eBy1w strong{color:#fff;font-weight:400;white-space:nowrap}.Mint-module__text___eBy1w>div{display:inline-block !important}", "",{"version":3,"sources":["webpack://./src/containers/mint/Mint.module.scss"],"names":[],"mappings":"AAAA,2BACE,kCAAA,CACA,aAAA,CAGF,2BACE,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,WAAA,CAEA,2BAAA,CAEA,kCACE,UAAA,CACA,eAAA,CACA,kBAAA,CAGF,+BACE,+BAAA","sourcesContent":[".tabs {\n  width: clamp(300px, 100%, 380px);\n  margin: 0 auto;\n}\n\n.text {\n  text-align: center;\n  grid-area: 2/2/2/2;\n  font-size: 16px;\n  position: absolute;\n  bottom: 30px;\n\n  color: var(--grayscale-dark);\n\n  strong {\n    color: white;\n    font-weight: 400;\n    white-space: nowrap;\n  }\n\n  > div {\n    display: inline-block !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "Mint-module__tabs___tBau1",
	"text": "Mint-module__text___eBy1w"
};
export default ___CSS_LOADER_EXPORT___;
