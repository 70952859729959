function Vitalik() {
  return (
    <div className="clontainer-vitalik">
      <div className="vitalik-oval-1" />
      <div className="vitalik-oval-2" />
    </div>
  );
}

export default Vitalik;
